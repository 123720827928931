<template>
  <div>
    <v-dialog
      v-model="table_chart.openRasterLayerTableChartPopup"
      min-width="500px"
      width="80vw"
      :fullscreen="mobileBreakpoints()"
      @click:outside="resetRasterForm()"
    >
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card>
        <v-card-title
          v-if="table_chart.isTable"
          class="green--text text--accent-4 d-flex justify-space-between"
        >
          <span>{{ $t('Marker.tab') }}</span>
          <v-icon
            color="red"
            size="30"
            @click="resetRasterForm()"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-title
          v-else
          class="green--text text--accent-4 d-flex justify-space-between"
        >
          <span>{{ $t('Marker.graph') }}</span>
          <v-icon
            color="red"
            size="30"
            @click="resetRasterForm()"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text v-if="renderContent">
          <div class="d-flex align-center">
            <v-select
              v-model="chipSelectedRasterLayers"
              :items="rasterLayers"
              :item-text="concatenateWorkspaceLayer"
              :label="$t('ServiceCard.layers')"
              chips
              multiple
              counter="2"
              return-object
              item-color="RMprimary"
              color="RMprimary"
              @input="limiterSelect"
            >
              <template v-slot:item="{ item }">
                {{ $t(`${item.ws_name}:${item.layer_name}_serviceID:${item.service_id}.label`) }}
              </template>
              <template v-slot:selection="{ item}">
                <v-chip
                  small
                  color="RMprimary"
                  class="mt-2"
                  text-color="white"
                  close
                  @click:close="chipSelectedRasterLayers.splice(chipSelectedRasterLayers.findIndex(chip => chip.layer_name === item.layer_name), 1)"
                >
                  <span>{{ $t(`${item.ws_name}:${item.layer_name}_serviceID:${item.service_id}.label`) }}</span>
                </v-chip>
              </template>
            </v-select>
            <v-btn
              color="#0da344"
              class="ml-4"
              small
              outlined
              :disabled="chipSelectedRasterLayers.length > 2 || chipSelectedRasterLayers.length <= 0"
              @click="updateRasterTableChart(chipSelectedRasterLayers)"
            >
              {{ $t('ServiceCard.confirm') }}
            </v-btn>
          </div>
          <div>
            <div
              class="mt-2 mb-2 d-flex flex-column"
            >
              <div class="mb-4 d-flex flex-column">
                <span><b>{{ $t('Ticketing.address') }}:</b> {{ map.placeAddress }}</span>
                <div>
                  <span class="mr-2"><b>{{ $t('Ticketing.latitude') }}:</b> {{ map.coordsLonLat[1] }}</span>
                  <span><b>{{ $t('Ticketing.longitude') }}:</b> {{ map.coordsLonLat[0] }}</span>
                </div>
              </div>
              <!-- Menù export -->
              <v-menu
                v-if="table_chart.isTable"
                offset-y
                class="text-center"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#4caf50"
                    small
                    outlined
                    max-width="min-content"
                    style="margin-left: auto;"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('ServiceCard.export') }}
                    <v-icon
                      v-if="table_chart.isTable"
                      class="ma-1"
                      color="#4caf50"
                    >
                      mdi-table
                    </v-icon>
                    <v-icon
                      v-else
                      class="ma-1"
                      color="#4caf50"
                    >
                      mdi-chart-line
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in exportTableExtensions"
                    :key="item.type"
                    :color="item.color"

                    @click="exportTable(item.type)"
                  >
                    <v-list-item-icon class="mr-1 ml-2">
                      <v-icon
                        :color="item.color"
                      >
                        mdi-file-export
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span
                          :style="`color: ${item.color}`"
                        >{{ item.type }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-alert
              v-model="wpsLimitsExceeded"
              dense
              type="warning"
              icon="mdi-alert-outline"
              class="mt-4"
              transition="scale-transition"
              dismissible
            >
              {{ $t('Table.wpsLimitsExceeded') }}
            </v-alert>
            <div v-if="table_chart.isTable">
              <v-data-table
                id="requests-table"
                fixed-header
                :height="table_chart.rasterTableItems.length > 8 ? '450px' : ''"
                :headers="table_chart.rasterTableHeaders"
                :items="table_chart.rasterTableItems"
                :items-per-page="10"
                :mobile-breakpoint="0"
                :footer-props="{
                  itemsPerPageAllText: $t('Table.all'),
                  itemsPerPageText: $t('Table.rowsPerPage'),
                  pageText: `{0}-{1} ${$t('Table.of')} {2}`
                }"
                :no-data-text="$t('Table.noData')"
                class="elevation-1"
              >
                <template v-slot:[`item.time`]="{ item }">
                  {{ item.time.includes('/') ? item.time : formatDate(item.time) }}
                </template>
              </v-data-table>
            </div>
            <div v-else>
              <div
                v-if="renderCharts"
              >
                <div
                  class="mb-2 d-flex justify-space-between align-center"
                >
                  <v-radio-group
                    v-model="mandatoryChart"
                    row
                  >
                    <v-radio
                      :label="$t('Graph.line')"
                      :ripple="true"
                      value="line"
                      color="RMprimary"
                    />
                    <v-radio
                      :label="$t('Graph.bar')"
                      :ripple="true"
                      value="bar"
                      color="RMprimary"
                    />
                  </v-radio-group>

                  <v-menu
                    offset-y
                    class="text-center"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="#4caf50"
                        small
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t('ServiceCard.export') }}
                        <v-icon
                          class="ma-1"
                          color="#4caf50"
                        >
                          mdi-chart-line
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="item in exportChartExtensions"
                        :key="item.type"
                        :color="item.color"
                        @click="exportChart(item.type)"
                      >
                        <v-list-item-icon class="mr-1 ml-2">
                          <v-icon
                            :color="item.color"
                          >
                            mdi-file-export
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span :style="`color: ${item.color}`">{{ item.type }}</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <line-chart
                  v-if="mandatoryChart === 'line'"
                  ref="chart"
                  :datasets="table_chart.rasterVectorChartDataSetLine"
                  @chart_data="chartData = $event"
                />
                <bar-chart
                  v-else
                  ref="chart"
                  :datasets="table_chart.rasterVectorChartDataSetBar"
                  @chart_data="chartData = $event"
                />
              </div>
            </div>
          </div>
        </v-card-text>
        <v-spacer
          v-else
          class="pb-16"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { logoRadarmeteo, logoCertification } from '@/base64img.js';
import moment from 'moment-business-days';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

export default {
  components: {
    LineChart: () => import('@/components/utils/LineChart'),
    BarChart: () => import('@/components/utils/BarChart')
  },
  props: {
    minStep: {
      type: Date,
      default: null
    },
    maxStep: {
      type: Date,
      default: null
    },
    selectedRasterLayer: {
      type: Object,
      default: null
    },
    rasterLayers: {
      type: Array,
      default: () => []
    },
    tableChartRangeDate: {
      type: String,
      default: ''
    },
    isAnimation: {
      type: Boolean,
      default: false
    },
    isDailyData: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mandatoryChart: 'line',
      overlay: false,
      renderCharts: false,
      renderContent: false,
      wpsLimitsExceeded: false,
      chipSelectedRasterLayers: [],
      chartData: {},
      exportTableExtensions: [
        { type: 'csv', color: 'green' },
        { type: 'pdf', color: 'red' }
        // {type: "doc", color: "#2196f3" }
      ],
      exportChartExtensions: [
        { type: 'png', color: '#43a047' },
        { type: 'jpg', color: '#43a047' }
      ]
    };
  },
  computed: {
    ...mapState(['map', 'app_user', 'table_chart'])
  },
  watch: {
    $route() {}
  },
  created() {
    if (!this.isAnimation) {
      this.chipSelectedRasterLayers = [...this.rasterLayers];
    } else {
      this.chipSelectedRasterLayers = [this.selectedRasterLayer];
    }

    this.updateRasterTableChart(this.chipSelectedRasterLayers);
  },
  methods: {
    ...mapActions(['getLayerCoordinatesDataAction', 'getUpdatedRasterTableChartAction', 'getElevationAction', 'getSeaDistanceAction', 'storeToastMessage']),
    mobileBreakpoints() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    concatenateWorkspaceLayer(item) {
      return `${item.ws_name}:${item.layer_name}`;
    },
    formatDate(date) {
      if (this.isDailyData) {
        return moment(date, moment.ISO_8601).format('DD/MM/YYYY');
      }

      return date.includes('T')
        ? moment(date, moment.ISO_8601).format('DD/MM/YYYY HH:mm')
        : date.replace('Z', '').replace(/-/g, '/');
    },
    async exportTable(type) {
      const reportDate = `Aggiornamento del: ${moment().format('DD/MM/YYYY')} delle ore ${moment().format('HH:mm')}`;
      const reportName = 'Radarmeteo - ' + this.$t(`ServiceCard.${this.selectedRasterLayer.service_id}.title`);
      const fileName = `${reportName} - ${moment().format('DD/MM/YYYY')} ore ${moment().format('HH:mm')}`;

      this.table_chart.rasterTableItems.forEach(items => {
        if (!(items.time).includes('/')) {
          items.time = this.formatDate(items.time);
        }
      });

      if (type === 'pdf') {
        await this.getSeaDistanceAction([this.map.coordsLonLat[1], this.map.coordsLonLat[0]]);
        await this.getElevationAction([this.map.coordsLonLat[1], this.map.coordsLonLat[0]]);

        require('jspdf-autotable');
        const header = [];
        this.table_chart.rasterTableHeaders.forEach(item => header.push({
          header: (item.text === 'time') ? this.$t('ServiceCard.date') : item.text,
          dataKey: item.value
        }));
        const data = [...this.table_chart.rasterTableItems];
        const doc = new jsPDF('l', 'mm', [297, 210], true);
        const totalPagesExp = '{total_pages_count_string}';

        const data1_alternativeTemplate = [{
          heading: this.$t('Ticketing.address'),
          value: this.map.placeAddress
        },
        {
          heading: 'Coordinate',
          value: this.map.coordsLonLat.toString()
        },
        {
          heading: 'Elevazione',
          value: `${parseFloat(this.table_chart.elevation).toFixed(1)} m`
        },
        {
          heading: 'Distanza dal mare',
          value: `${parseFloat(this.table_chart.sea_distance).toFixed(1)} km`
        }];
        const data2_alternativeTemplate = [];

        Object.keys(data[0]).forEach(item => {
          if (item !== 'longitude' && item !== 'latitude' && item !== 'time') {
            const obj = {
              heading: this.$t(`${item}_serviceID:${this.selectedRasterLayer.service_id}.label`),
              value: data[0][item]
            };
            data2_alternativeTemplate.push(obj);
          }
        });

        const pageContent = function (data) {
          // HEADER
          doc.setFontSize(20);
          doc.setTextColor(40);

          if (logoRadarmeteo) {
            doc.addImage(logoRadarmeteo, 'JPEG', data.settings.margin.left, 5, 10, 10);
          }

          doc.text(reportName, data.settings.margin.left + 13, 13);

          doc.setFontSize(6);
          doc.text(reportDate, data.settings.margin.left + 238, 13);

          // FOOTER
          let str = data.pageCount;

          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' di ' + totalPagesExp;
          }

          str += '          Radarmeteo s.r.l. Via IV Novembre, 117 - 35020, Due Carrare (PD) - Italia - Tel: (+39) 049 9125902 - Email: assistenza@radarmeteo.com - Web: www.radarmeteo.com';

          doc.setFontSize(6);
          doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);

          if (logoCertification) {
            doc.addImage(logoCertification, 'JPEG', doc.internal.pageSize.width - 34.25, doc.internal.pageSize.height - 14, 27.25, 10);
          }
        };

        doc.autoTable({
          columns: this.table_chart.header_alternativeTemplate,
          body: data1_alternativeTemplate,
          margin: { top: 20, horizontal: 7, left: 20, right: 20 },
          head: [[{ content: 'Ubicazione del rischio', colSpan: 2, styles: { fillColor: [12, 163, 68] } }]],
          bodyStyles: { valign: 'top' },
          styles: {
            overflow: 'linebreak',
            cellWidth: '150',
            fontSize: 6
          },
          columnStyles: { text: { cellWidth: 'auto' }, 0: { halign: 'left', cellWidth: 100, lineWidth: { right: 0.2 } } }
        });

        if (this.isAnimation) {
          doc.autoTable({
            didDrawPage: pageContent,
            columns: header,
            body: data,
            margin: { top: 20, horizontal: 7, left: 20, right: 20 },
            headStyles: { fillColor: [12, 163, 68] },
            bodyStyles: { valign: 'top' },
            styles: {
              overflow: 'linebreak',
              cellWidth: '150',
              fontSize: 6
            },
            columnStyles: { text: { cellWidth: 'auto' } }
          });
        } else {
          doc.autoTable({
            didDrawPage: pageContent,
            columns: this.table_chart.header_alternativeTemplate,
            body: data2_alternativeTemplate,
            margin: { top: 20, horizontal: 7, left: 20, right: 20 },
            head: [[{ content: 'Hazard Scores*', colSpan: 2, styles: { fillColor: [12, 163, 68] } }]],
            bodyStyles: { halign: 'center', valign: 'top' },
            styles: {
              overflow: 'linebreak',
              cellWidth: '150',
              fontSize: 6
            },
            columnStyles: { text: { cellWidth: 'auto' }, 0: { halign: 'left', cellWidth: 100, lineWidth: { right: 0.2 } } }
          });

          doc.setFontSize(6);
          doc.text('*la metodologia è riportata sui documenti tecnici', doc.lastAutoTable.settings.margin.left, doc.lastAutoTable.finalY + 4);
        }

        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }

        doc.save(fileName + '.pdf');
      } else {
        const rasterTableHeaders = [{ text: 'latitude', value: 'latitude', align: ' d-none' }, { text: 'longitude', value: 'longitude', align: ' d-none' }, ...this.table_chart.rasterTableHeaders];
        const currentTableItems = [...this.table_chart.rasterTableItems];

        const headers = rasterTableHeaders.map(header => {
          if (header.text === 'time') {
            return { name: header.text, alias: this.$t('ServiceCard.date'), flex: 10 };
          } else {
            return { name: header.text, alias: header.text, flex: 1 };
          }
        });

        // eslint-disable-next-line no-undef
        objectExporter({
          exportable: currentTableItems,
          type: type,
          headers: headers,
          fileName: fileName,
          repeatHeader: false,
          headerStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;',
          cellStyle: 'border: 1px solid lightgray; margin-bottom: -1px;',
          sheetName: 'Raster Table',
          documentTitle: 'Raster Table',
          documentTitleStyle: 'display:none'
        });
      }
    },
    exportChart(type) {
      html2canvas(this.$refs.chart.$refs.canvas, {
        backgroundColor: '#FFFFFF',
        allowTaint: true
      }).then(canvas => {
        const link = document.createElement('a');
        const nameFile = (this.map.placeAddress != '' ? `${this.map.placeAddress}` : `Lat: ${this.map.coordsLonLat[1]} Lon: ${this.map.coordsLonLat[0]}`).trim();
        const variabili = this.chartData.datasets.map(data => data.label.trim().replace(/\s+/g, '-')).join(' - ');
        const typeChart = (this.mandatoryChart == 'line' ? `${this.$t('Graph.line')}` : `${this.$t('Graph.bar')}`).trim();
        link.download = `Radarmeteo - ${typeChart} - ${nameFile} - ${variabili}.${type}`;
        link.href = canvas.toDataURL(`image/${type}`);
        link.click();
      });
    },
    resetRasterForm() {
      this.chipSelectedRasterLayers = [];
      this.table_chart.allWorkspacesData = { data: {}, metadata: {} },
      this.table_chart.rasterTableHeaders = [{ text: this.$t('ServiceCard.date') }];
      this.table_chart.rasterTableItems = [];
      this.table_chart.rasterVectorChartLabels = [];
      this.table_chart.rasterVectorChartDataSetLine = [];
      this.table_chart.rasterVectorChartDataSetBar = [];
      this.renderContent = false;
      this.table_chart.openRasterLayerTableChartPopup = false;
    },
    async updateRasterTableChart(rasters) {
      this.overlay = true;
      this.table_chart.rasterTableItems = [];
      this.table_chart.allWorkspacesData = { data: {}, metadata: {} };

      if (rasters.length > 0) {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const groupLayersByWorkspace = rasters.reduce((r, a) => {
          r[a.ws_name] = [...r[a.ws_name] || [], a];

          return r;
        }, {});

        this.minStep.setSeconds(0, 0);
        this.maxStep.setSeconds(0, 0);

        this.checkWpsLimits(groupLayersByWorkspace);

        if (!this.wpsLimitsExceeded) {
          for await (const workspace of Object.keys(groupLayersByWorkspace)) {
            const data = {
              time: this.tableChartRangeDate,
              username: this.app_user.username,
              workspace: workspace,
              layers: groupLayersByWorkspace[workspace].map(layer => layer.layer_name),
              latitude: this.map.coordsLonLat[1],
              longitude: this.map.coordsLonLat[0],
              output: 'json',
              timezone: timeZone,
              service_name: rasters[0].service_name,
              service_id: groupLayersByWorkspace[workspace].map(layer => layer.service_id)[0],
              group: this.$route.params.id,
              runtime: this.map.runtime[groupLayersByWorkspace[workspace].map(layer => layer.service_id)[0]]
            };
            await this.getLayerCoordinatesDataAction(data);
          }

          this.table_chart.allWorkspacesData.service_id = this.selectedRasterLayer.service_id;
          await this.getUpdatedRasterTableChartAction(this.table_chart.allWorkspacesData);
        }

        if (this.table_chart.isTable) {
          this.table_chart.rasterTableHeaders = [{ text: this.$t('ServiceCard.date'), value: 'time' }];

          rasters.forEach(layer => {
            this.table_chart.rasterTableHeaders.push({
              text: `${this.$t(`${layer.ws_name}:${layer.layer_name}_serviceID:${layer.service_id}.label`)}${this.$t(`${layer.ws_name}:${layer.layer_name}_serviceID:${layer.service_id}.udm`)}`,
              value: `${layer.ws_name}:${layer.layer_name}`,
              sortable: true
            });
          });

          this.table_chart.rasterTableItems.forEach(item => {
            const header = this.table_chart.rasterTableHeaders.filter(e => !Object.keys(item).includes(e.value));

            if (header.length > 0) {
              header.forEach(h => item[h.value] = 'N/A');
            }
          });
        } else {
          this.renderCharts = false;
          this.$nextTick(() => {
            this.renderCharts = true;
          });
        }

      } else {
        this.resetRasterForm();
      }

      this.overlay = false;
      this.renderContent = true;
    },
    checkWpsLimits(workspaces) {
      // let HRS_limits = false;
      // let HFS_limits = false;
      Object.keys(workspaces).forEach(ws => {
        if (ws.toUpperCase().startsWith('HRS')) {
          const time = this.tableChartRangeDate.split('/');
          const [startDate, endDate] = time.map(t => moment(t));
          this.wpsLimitsExceeded = !!endDate.diff(startDate, 'year');
          // const timeDiff = endDate.diff(startDate, 'year');
          // HRS_limits = timeDiff > 0 || workspaces[ws].length > 5;
        }
        // if (ws.toUpperCase().startsWith('HFS')) {
        //   HFS_limits = workspaces[ws].length > 16;
        // }
      });
      // this.wpsLimitsExceeded = HRS_limits || HFS_limits;
    },
    limiterSelect(e) {
      if (e.length > 2) {
        this.storeToastMessage({
          text: this.$t('Graph.selectableLimit'),
          type: 'warning'
        });
        e.pop();
      }
    }
  }
};
</script>

<style scoped>
#requests-table {
  white-space: nowrap !important;
}
</style>
